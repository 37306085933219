<template>
  <HeaderOutline v-if="!userState.loading" id="navbar" class="sticky top-0 z-30" data-testid="header">
    <div class="bg-gray-900">
      <NavigationBarMeetingBanner
        v-if="firstActiveMeeting"
        class="px-4"
        :first-active-meeting="firstActiveMeeting"
        @go-to-meeting="goToMeeting(firstActiveMeeting)"
      />
    </div>
    <div class="relative flex justify-between items-center bg-white px-4 py-5 xl_py-0">
      <NuxtLink v-analytics="'navBarVrifyLogoSelected'" class="mr-4" :to="`${config.public.WEB_ENDPOINT}`">
        <VrifyLogo class="text-gray-900" width="150" />
      </NuxtLink>
      <div class="flex justify-between items-center">
        <button class="cursor-pointer appearance-none bg-transparent xl_hidden" @click="toggleMobileMenu">
          <Bars3Icon class="w-6" />
        </button>
      </div>
      <NavigationBarMobileMenu
        class="xl_hidden"
        :is-showing="isMobileNavBarShowing"
        :user="userState.user"
        :user-role="userRole"
        :user-has-companies="userHasCompanies"
        :vpe-url="vpeUrl"
        :is-inbox-visible="isInboxVisible"
        :inbox-count="inboxCount"
        @toggle="toggleMobileMenu"
        @login="goToLoginPage"
        @logout="handleLogout"
      />
      <NavigationBarDesktopMenu
        class="hidden xl_block"
        :user="userState.user"
        :user-role="userRole"
        :user-has-companies="userHasCompanies"
        :vpe-url="vpeUrl"
        :is-inbox-visible="isInboxVisible"
        :inbox-count="inboxCount"
        @login="goToLoginPage"
        @logout="handleLogout"
      />
    </div>
    <VAlert :is-visible="alerts.isAlertVisible" :timeout="alerts.alertTimeout" :banner-type="alerts.alertType" @clear-banner="hideAlert">
      <p class="mb-0">{{ alerts.alertMessage }}</p>
    </VAlert>
  </HeaderOutline>
</template>

<script lang="ts" setup>
import type { Meeting } from '@/entities/meeting'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import VrifyLogo from '~/assets/images/vrify-logo.svg'

const config = useRuntimeConfig()
const route = useRoute()
const { state: userState, userRole, setSelectedCompany, userHasCompanies, logout, inboxCount } = useUser()
const { alerts, hideAlert } = useAlerts()
const { firstActiveMeeting } = useMeetings({ userState, setSelectedCompany })

const isMobileNavBarShowing = ref(false)

const vpeUrl = config.public.ADMIN_ENDPOINT
watch(
  route,
  () => {
    // Clear flash messages on route changes
    if (!alerts.value.shouldPersistThroughNav) hideAlert()
  },
  { immediate: true },
)

const toggleMobileMenu = () => {
  isMobileNavBarShowing.value = !isMobileNavBarShowing.value
}

const goToLoginPage = async () => {
  toggleMobileMenu()

  navigateTo({
    name: 'login',
    query: {
      ...route.query,
      redirectedFrom: window.location.pathname,
    },
  })
}

const goToMeeting = (m: Meeting | null) => {
  if (m) {
    navigateTo(
      `${config.public.WEB_ENDPOINT}meeting/${m.share_id}`,
      { external: true },
      // {
      //   name: 'meeting-id',
      //   params: {
      //     id: m.share_id,
      //   },
      // }
    )
  }
}

const isInboxVisible = computed(() => !!userState.value.user?.is_inbox_visible)

const handleLogout = () => {
  toggleMobileMenu()
  logout()
}
</script>

<style lang="stylus">
.loading-header
  height 4.1875rem

.navbar-link
  @apply appearance-none bg-transparent block text-left text-gray-800 font-medium hover_text-gray-600 p-4 xl_py-6 xl_px-2

  @screen xl
    &.router-link-active
      @apply text-gray-900 font-bold

      &:not(.flyout-menu-link)
        padding-bottom 1.375rem
        @apply border-b-2
</style>
