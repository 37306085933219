<template lang="pug">
nav.flex-grow
  ul.mb-0.flex.items-center
    template(v-if="isLoggedIn")
      li.mx-4
        NuxtLink.navbar-link(data-testid="discover" to="/discover" v-analytics="'navBarDiscoverSelected'") Discover
      li.mx-4
        NuxtLink.navbar-link(data-testid="meetings" :to="{ name: 'meetings' }" v-analytics="'navBarMeetingsSelected'")
          | Meetings
      li.mx-4(v-if="isInboxVisible")
        a.navbar-link(data-testid="inbox" :href="vpeUrl + '/inbox'" v-analytics="'navBarInboxSelected'")
          | Inbox
          span.bg-red-800.rounded-full.text-white.px-3.py-1.ml-2.text-xs.font-semibold(v-if="inboxCount") {{ inboxCount }}
      li.mx-4(v-if="userRole && (userRole === USER_ROLES.superAdmin || userHasCompanies)")
        a.navbar-link(data-testid="editor" href="/admin" v-analytics="'navBarVpeSelected'") Admin

      li.relative.flex-grow.text-right(data-testid="profile" ref="profileFlyoutMenu")
        button.appereance-none.bg-transparent.hovertext-gray-600(@click="isProfileFlyoutMenuShowing = !isProfileFlyoutMenuShowing")
          .w-12.h-12.flex.justify-center.items-center.text-white.rounded-full.bg-gray-900(:class="{ 'bg-gold-700': userRole }") {{ user.initials }}
        ul.absolute.top-14.right-0.py-2.z-10.w-40.text-left.transform.opacity-0.translate-y-0.transition-all.bg-white.rounded-lg.shadow-lg.ring-1.ring-gray-200.duration-75(
          :class="{ invisible: !isProfileFlyoutMenuShowing, 'opacity-100': isProfileFlyoutMenuShowing, 'translate-y-1': !isProfileFlyoutMenuShowing }"
        )
          li.px-4.py-2(@click="isProfileFlyoutMenuShowing = !isProfileFlyoutMenuShowing")
            NuxtLink.w-full.navbar-link.flyout-menu-link(:to="{ name: 'user-profile' }" v-analytics="'navBarUserProfileSelected'") My Profile
          li.px-4.py-2(@click="isProfileFlyoutMenuShowing = !isProfileFlyoutMenuShowing")
            button.w-full.navbar-link.flyout-menu-link.hovertext-gray-600(
              @click="$emit('logout')"
              data-testid="logout"
              v-analytics="'navBarLogoutSelected'"
            ) Log Out

    template(v-else)
      li.mx-4
        NuxtLink.navbar-link(data-testid="discover" to="/discover" v-analytics="'navBarDiscoverSelected'") Discover

      li.ml-4.mr-16.relative
        NuxtLink.navbar-link(data-testid="meetings" :to="{ name: 'meetings' }" v-analytics="'navBarMeetingsSelected'")
          | Join a Meeting

      li.flex-grow.text-right
        button.apppearance-none.bg-transparent.mx-2.hovertext-gray-600(
          data-testid="login"
          @click="$emit('login')"
          v-analytics="'navBarLoginSelected'"
        ) Log In
</template>

<script lang="ts" setup>
import type { User } from '@/entities/user'
import { USER_ROLES } from '@/lib/constants/user-roles'
import type { DeepReadonly } from 'vue'
const NuxtLink = resolveComponent('NuxtLink')

const props = defineProps<{
  user: DeepReadonly<User> | undefined
  userRole: string
  userHasCompanies: boolean
  vpeUrl: string
  isInboxVisible: boolean
  inboxCount: number
}>()

defineEmits(['login', 'logout'])

const profileFlyoutMenu = ref()
const isProfileFlyoutMenuShowing = ref(false)
const isLoggedIn = computed(() => !!props.user)

function closeOnClickAway(e: MouseEvent) {
  if (e.target !== profileFlyoutMenu.value && !profileFlyoutMenu.value?.contains(e.target)) {
    isProfileFlyoutMenuShowing.value = false
  }
}

onMounted(async () => {
  document.addEventListener('click', closeOnClickAway)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closeOnClickAway)
})
</script>

<style lang="stylus" scoped>
.flyout-menu-link
  @apply p-0
</style>
