<template lang="pug">
nav.mobile-menu.bg-white.rounded-lg.m-0.absolute.top-2.shadow-lg.overflow-hidden.transition-all.transform.origin-top-right.duration-200(
  data-testid="mobile-menu"
  :class="['left-1/2', '-translate-x-1/2', 'lg_py-0', isShowing ? 'opacity-100' : 'opacity-0', isShowing ? 'scale-100' : 'scale-95', { invisible: !isShowing }]"
)
  .flex.justify-between.items-center.p-4
    div(@click="$emit('toggle')")
      NuxtLink(:to="`${config.public.WEB_ENDPOINT}`" v-analytics="'navBarVrifyLogoSelected'")
        VrifyLogo.text-gray-900(width="120")

    .flex.justify-between.items-center
      button.apperance.bg-transparent(@click="$emit('toggle')")
        XMarkIcon.w-6

  ul
    template(v-if="isLoggedIn")
      li.nav-item(@click="$emit('toggle')")
        NuxtLink.w-full.navbar-link(data-testid="discover-mobile" to="/discover" v-analytics="'navBarDiscoverSelected'") Discover
      li.nav-item(@click="$emit('toggle')")
        NuxtLink.w-full.navbar-link(data-testid="meetings-mobile" :to="{ name: 'meetings' }" v-analytics="'navBarMeetingsSelected'")
          | Meetings
      li.nav-item(v-if="isInboxVisible" @click="$emit('toggle')")
        a.navbar-link(data-testid="inbox-mobile" :href="vpeUrl + '/inbox'" v-analytics="'navBarInboxSelected'")
          | Inbox
          span.bg-red-800.rounded-full.text-white.px-3.py-1.ml-2.text-xs.font-semibold(v-if="inboxCount") {{ inboxCount }}
      li.nav-item
        a.navbar-link(
          v-if="userRole && (userRole === USER_ROLES.superAdmin || userHasCompanies)"
          data-testid="editor-mobile"
          href="/admin"
          v-analytics="'navBarVpeSelected'"
        ) Admin
      li.mt-4.nav-item(@click="$emit('toggle')")
        NuxtLink.w-full.navbar-link(:to="{ name: 'user-profile' }" data-testid="profile-mobile" v-analytics="'navBarUserProfileSelected'") My Profile
      li.nav-item
        button.w-full.navbar-link(@click="$emit('logout')" data-testid="logout-mobile" v-analytics="'navBarLogoutSelected'") Log Out

    template(v-else)
      hr.mt-1
      li.nav-item(@click="$emit('toggle')")
        NuxtLink.w-full.navbar-link(to="/discover`" data-testid="discover-mobile" v-analytics="'navBarDiscoverSelected'") Discover
      li.nav-item(@click="$emit('toggle')")
        NuxtLink.w-full.navbar-link(data-testid="meetings-mobile" :to="{ name: 'meetings' }" v-analytics="'navBarMeetingsSelected'")
          | Join a Meeting
      hr.mt-1
      li.nav-item.mt-4
        button.w-full.navbar-link(data-testid="login-mobile" @click="$emit('login')" v-analytics="'navBarLoginSelected'") Log In
</template>

<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { USER_ROLES } from '@/lib/constants/user-roles'
import VrifyLogo from '~/assets/images/vrify-logo.svg'

const config = useRuntimeConfig()

const props = defineProps({
  isShowing: {
    type: Boolean,
    required: true,
  },
  user: {
    type: Object,
    default: null,
  },
  userRole: {
    type: String,
    default: null,
  },
  userHasCompanies: {
    type: Boolean,
    required: true,
  },
  vpeUrl: {
    type: String,
    required: true,
  },
  isInboxVisible: {
    type: Boolean,
    required: true,
  },
  inboxCount: {
    type: Number,
    default: null,
  },
})

defineEmits(['toggle', 'login', 'logout'])

const isLoggedIn = computed(() => !!props.user)
</script>

<style lang="stylus" scoped>
.mobile-menu
  width 95%

.nav-item
  @apply w-full

  &:first-child
    @apply mt-4

  &:last-child
    @apply mb-4

.router-link-active
  @apply text-gray-600
</style>
